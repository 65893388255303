<template>
  <div class="p-2">
    <b-form-group
      label="发布方式："
      label-for="description"
    >
      <div class="demo-inline-spacing">
        <b-form-radio
          v-model="releaseType"
          name="some-radios"
          value="all"
        >
          所有设备
        </b-form-radio>
        <b-form-radio
          v-model="releaseType"
          name="some-radios"
          value="part"
        >
          指定设备
        </b-form-radio>
      </div>
    </b-form-group>
    <div class="mt-3" v-show="tableShow">
      <el-table
        ref="deviceTable"
        :data="deviceList"
        style="width: 100%;margin-bottom: 20px;"
        :header-cell-style="{background:'#f3f2f7',fontSize: '0.857rem', color: '#6e6b7b',textAlign: 'inherit', lineHeight: '15.5px !important', paddingTop: '2px',paddingBottom: '2px' }"
        row-key="id"
        @selection-change="selectCheckBox"
      >
        <el-table-column
          type="selection"
          color="red"
          width="55">
        </el-table-column>
        <el-table-column
          prop="id"
          label="标识"
          sortable
          width="180">
        </el-table-column>
        <el-table-column
          prop="name"
          label="名称"
          sortable
          width="180">
        </el-table-column>
        <el-table-column
          prop="productName"
          label="产品名称">
        </el-table-column>
        <el-table-column
          prop="state.value"
          label="状态">
          <template slot-scope="scope">
            <b-badge
              variant="success"
              v-if="scope.row.state.value === 'online'"
            >
              在线
            </b-badge>
            <b-badge
              v-if="scope.row.state.value === 'offline'"
            >
              离线
            </b-badge>
            <b-badge
              variant="warning"
              v-if="scope.row.state.value === 'notActive'"
            >
              未启用
            </b-badge>
          </template>
        </el-table-column>
      </el-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">共{{total}}条记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="mt-4">
      <b-button
        class="flex-grow-1 mr-2 mb-2 full-width"
        variant="primary"
        @click="validationFormInfo">
        确认绑定
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  getDeviceList, deploy, deployAll, getAllDeviceListCount, getTaskDevice,
} from '@/api/ops/firmware-upgrade'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormRadio,
  BBadge,
  BPagination,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormRadio,
    BBadge,
    BPagination,
    BButton,
  },
  props: {
    firmware: {
      type: Object,
      default: () => {},
    },
    formData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // 发布方式  all-全部设备  part-指定设备
      releaseType: 'all',
      // 选择设备id
      selectIds: [],
      // 新增的升级任务
      task: {},
      deviceList: [],
      tableShow: false,
      total: 0,
      currentPage: 1,
      perPage: 8,
    }
  },
  watch: {
    releaseType(val) {
      if (val === 'all') {
        this.tableShow = false
      } else {
        this.tableShow = true
      }
    },
  },
  created() {
    // this.editAction()
    getDeviceList(this.currentPage - 1, this.firmware.productId).then(resp => {
      this.deviceList = resp.data.result.data
      this.total = resp.data.result.total
    })
    this.editAction()
  },
  methods: {
    // 第二页提交
    validationFormInfo() {
      if (this.releaseType === 'all') {
        deployAll(this.formData.id).then(() => {
          this.$xtoast.success('绑定所有设备成功！')
          this.$emit('bindSuccess')
        })
      } else {
        if (this.selectIds.length === 0) {
          this.$xtoast.success('请选择指定设备！')
          return
        }
        deploy(this.formData.id, this.selectIds).then(() => {
          this.$xtoast.success('绑定指定设备成功！')
          this.$emit('bindSuccess')
        })
      }
    },
    // 升级设备选择
    selectCheckBox(selection) {
      if (selection.length < this.selectIds.length) {
        this.$xtoast.warning('已发布设备不能取消！')
        let selectIds = []
        selection.forEach(select => {
          selectIds.push(select.id)
        })
        let difference = this.selectIds.filter(item => !selectIds.includes(item)) // 差集
        this.deviceList.forEach(device => {
          if (device.id === difference[0]) {
            this.$refs.deviceTable.toggleRowSelection(device, true)
          }
        })
      } else {
        this.selectIds = []
        selection.forEach(item => {
          this.selectIds.push(item.id)
        })
      }
    },
    // 编辑加载数据
    editAction() {
      let taskDeviceCount = 0
      // 查询任务涉及设备与所有设备做数量对比  判断所有设备还是指定设备
      getAllDeviceListCount(this.firmware.productId).then(resp => {
        getTaskDevice(this.firmware.id, this.formData.id).then(res => {
          taskDeviceCount = res.data.result.total
          if (resp.data.result === taskDeviceCount || taskDeviceCount === 0) {
            this.releaseType = 'all'
          } else {
            this.releaseType = 'part'
          }
          this.deviceList.forEach(device => {
            res.data.result.data.forEach(task => {
              if (device.id === task.deviceId) {
                this.$refs.deviceTable.toggleRowSelection(device, true)
              }
            })
          })
        })
      })
    },
  },
}
</script>
