<template>
  <div class="p-2">
    <b-card-title class="d-flex ">
      <div class="x-indicator mr-50 x-text-bold" />
      <div class="d-flex align-items-center x-text-bold ">
        基本信息
      </div>
    </b-card-title>
    <div>
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="任务名称"
              label-for="name"
            >
              <b-form-input
                v-model="formData.name"
                placeholder="请输入任务名称"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="超时时间"
              label-for="timeoutSeconds"
            >
              <b-form-input
                type="number"
                v-model="formData.timeoutSeconds"
                placeholder="请输入超时时间"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="推送方式"
              label-for="mode.value"
            >
                <v-select
                  v-model="formData.mode"
                  :options="modeList"
                  placeholder="请选择推送方式"
                  label="text"
                  disabled
                >
                  <template slot="no-options">{{$t('noData')}}</template>
                </v-select>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="描述"
              label-for="description"
            >
              <b-form-textarea
                v-model="formData.description"
                placeholder="请输入描述"
                readonly
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </div>
    <b-card-title class="d-flex ">
      <div class="x-indicator mr-50 x-text-bold" />
      <div class="d-flex align-items-center x-text-bold ">
        设备绑定
      </div>
    </b-card-title>
    <div>
      <b-form-group
        label="发布方式："
        label-for="description"
      >
        <div class="demo-inline-spacing">
          <b-form-radio
            v-model="releaseType"
            name="some-radios"
            value="all"
            disabled
          >
            所有设备
          </b-form-radio>
          <b-form-radio
            v-model="releaseType"
            name="some-radios"
            value="part"
            disabled
          >
            指定设备
          </b-form-radio>
        </div>
      </b-form-group>
      <div class="mt-3" v-show="tableShow">
        <el-table
          ref="deviceTable"
          :data="deviceList"
          style="width: 100%;margin-bottom: 20px;"
          :header-cell-style="{background:'#f3f2f7',fontSize: '0.857rem', color: '#6e6b7b',textAlign: 'inherit', lineHeight: '15.5px !important', paddingTop: '2px',paddingBottom: '2px' }"
          row-key="id"
          @selection-change="selectCheckBox"
        >
          <el-table-column
            type="selection"
            :selectable="selectable"
            width="55">
          </el-table-column>
          <el-table-column
            prop="id"
            label="标识"
            sortable
            width="180">
          </el-table-column>
          <el-table-column
            prop="name"
            label="名称"
            sortable
            width="180">
          </el-table-column>
          <el-table-column
            prop="productName"
            label="产品名称">
          </el-table-column>
          <el-table-column
            prop="state.value"
            label="状态">
            <template slot-scope="scope">
              <b-badge
                variant="success"
                v-if="scope.row.state.value === 'online'"
              >
                在线
              </b-badge>
              <b-badge
                v-if="scope.row.state.value === 'offline'"
              >
                离线
              </b-badge>
              <b-badge
                variant="warning"
                v-if="scope.row.state.value === 'notActive'"
              >
                未启用
              </b-badge>
            </template>
          </el-table-column>
        </el-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">共{{total}}条记录</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <b-card-title class="d-flex ">
      <div class="x-indicator mr-50 x-text-bold" />
      <div class="d-flex align-items-center x-text-bold ">
        升级信息
      </div>
    </b-card-title>
    <div>
      <b-row class="mt-3 ecommerce-application">
        <b-col
          cols="3"
        >
          <div
            class="x-card x-anim-card p-2"
          >
            <b-row
              align="center"
            >
              <b-col
                md="6"
              >
                <div class="d-flex align-items-center ">
                  <div class="x-dot bg-warning"></div>
                  等待升级
                </div>
              </b-col>
            </b-row>
            <b-row  align="center">
              <b-col
                md="6"
                class="mt-1"
              >
                <div>设备数量：<span class="font-medium-3">{{waitingCount}}</span></div>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col
          cols="3"
        >
          <div
            class="x-card x-anim-card p-2"
          >
            <b-row
              align="center"
            >
              <b-col
                md="6"
              >
                <div class="d-flex align-items-center ">
                  <div class="x-dot bg-primary"></div>
                  升级中
                </div>

              </b-col>
            </b-row>
            <b-row  align="center">
              <b-col
                md="6"
                class="mt-1"
              >
                <div>设备数量：<span class="font-medium-3">{{processingCount}}</span></div>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col
          cols="3"
        >
          <div
            class="x-card x-anim-card p-2"
          >
            <b-row
              align="center"
            >
              <b-col
                md="6"
              >
                <div class="d-flex align-items-center ">
                  <div class="x-dot bg-success"></div>
                  升级完成
                </div>

              </b-col>
            </b-row>
            <b-row  align="center">
              <b-col
                md="6"
                class="mt-1"
              >
                <div>设备数量：<span class="font-medium-3">{{successCount}}</span></div>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col
          cols="3"
        >
          <div
            class="x-card x-anim-card p-2"
          >
            <b-row
              align="center"
            >
              <b-col
                md="6"
              >
                <div class="d-flex align-items-center ">
                  <div class="x-dot bg-danger"></div>
                  升级失败
                </div>
              </b-col>
            </b-row>
            <b-row  align="center">
              <b-col
                md="6"
                class="mt-1"
              >
                <div>设备数量：<span class="font-medium-3">{{failedCount}}</span></div>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import {
  getDeviceList, getStateCount, getAllDeviceListCount, getTaskDevice,
} from '@/api/ops/firmware-upgrade'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormRadio,
  BBadge,
  BPagination,
  BForm,
  BCardTitle,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    vSelect,
    BFormRadio,
    BBadge,
    BPagination,
    BForm,
    BCardTitle,
  },
  props: {
    firmware: {
      type: Object,
      default: () => {},
    },
    formData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // 发布方式  all-全部设备  part-指定设备
      releaseType: 'all',
      // 选择设备id
      selectIds: [],
      // 新增的升级任务
      task: {},
      // 待升级设备数量
      waitingCount: 0,
      // 升级中设备数量
      processingCount: 0,
      // 升级成功设备数量
      successCount: 0,
      // 升级失败设备数量
      failedCount: 0,
      deviceList: [],
      tableShow: false,
      total: 0,
      currentPage: 1,
      perPage: 8,
      modeList: [{
        text: '平台推送',
        value: 'push',
      }, {
        text: '设备拉取',
        value: 'pull',
      }],
    }
  },
  watch: {
    releaseType(val) {
      if (val === 'all') {
        this.tableShow = false
      } else {
        this.tableShow = true
      }
    },
  },
  created() {
    getDeviceList(this.currentPage - 1, this.firmware.productId).then(resp => {
      this.deviceList = resp.data.result.data
      this.total = resp.data.result.total
    })
    this.editAction()
  },
  methods: {
    // 升级设备选择
    selectCheckBox(selection) {
      if (selection.length < this.selectIds.length) {
        let selectIds = []
        selection.forEach(select => {
          selectIds.push(select.id)
        })
        let difference = this.selectIds.filter(item => !selectIds.includes(item)) // 差集
        this.deviceList.forEach(device => {
          if (device.id === difference[0]) {
            this.$refs.deviceTable.toggleRowSelection(device, true)
          }
        })
      } else {
        this.selectIds = []
        selection.forEach(item => {
          this.selectIds.push(item.id)
        })
      }
    },
    // 查寻各状态下的设备数量
    getStateCount() {
      const stateArr = ['waiting', 'processing', 'success', 'failed']
      stateArr.forEach(state => {
        getStateCount(this.formData.id, state).then(resp => {
          if (state === 'waiting') {
            this.waitingCount = resp.data.result
          }
          if (state === 'processing') {
            this.processingCount = resp.data.result
          }
          if (state === 'success') {
            this.successCount = resp.data.result
          }
          if (state === 'failed') {
            this.failedCount = resp.data.result
          }
        })
      })
    },
    // 编辑加载数据
    editAction() {
      let taskDeviceCount = 0
      // 查询任务涉及设备与所有设备做数量对比  判断所有设备还是指定设备
      getAllDeviceListCount(this.firmware.productId).then(resp => {
        getTaskDevice(this.firmware.id, this.formData.id).then(res => {
          taskDeviceCount = res.data.result.total
          if (resp.data.result === taskDeviceCount || taskDeviceCount === 0) {
            this.releaseType = 'all'
          } else {
            this.releaseType = 'part'
          }
          this.deviceList.forEach(device => {
            res.data.result.data.forEach(task => {
              if (device.id === task.deviceId) {
                this.$refs.deviceTable.toggleRowSelection(device, true)
              }
            })
          })
        })
      })
      this.getStateCount()
    },
    selectable(row) {
      // id不在selectIds中禁止选中
      if (this.selectIds.indexOf(row.id) >= 0) {
        return true
      }
      return false
    },
  },
}
</script>
