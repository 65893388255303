<template>
  <div>
    <b-card class="mb-2">
      <b-row class="d-flex align-items-center">
        <b-col cols="2">
          <feather-icon
            size="30"
            class="text-primary x-link"
            icon="ChevronLeftIcon"
            @click="back()"
          />
        </b-col>
       <b-col cols="4">
         <feather-icon
           icon="GitMergeIcon"
           class="mr-50"
           size="28"
           color="#7367F0"
         />
         <span class="ml-1">固件名称：{{firmware.name}}</span>
       </b-col>
        <b-col cols="4">
          <feather-icon
            icon="ServerIcon"
            class="mr-50"
            size="28"
            color="#7367F0"
          />
          <span class="ml-1">所属产品：{{firmware.productName}}</span>
          <b-button
            v-b-tooltip.hover
            title="查看产品"
            class="btn-icon rounded-circle"
            variant="flat-primary"
            :to="{ name: 'ec-product-view', params: { id: firmware.productId }}">
            <feather-icon
              icon="LinkIcon"
              size="20"
            />
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-tabs v-model="tabIndex">
        <b-tab
        active
        title="基本信息"
      >
        <section id="card-actions">
          <b-row>
            <!-- card actions -->
            <b-col cols="12">
              <b-card
                ref="cardAction"
              >
                <div>
                  <table
                    class="align-content-center text-center viewTable"
                  >
                    <tr>
                      <th>
                        固件名称
                      </th>
                      <td>
                        {{ firmware.name }}
                      </td>
                      <th>
                        所属产品
                      </th>
                      <td>
                        {{firmware.productName}}
                      </td>
                      <th>
                        版本
                      </th>
                      <td>
                        {{firmware.version}}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        版本序号
                      </th>
                      <td>
                        {{firmware.versionOrder}}
                      </td>
                      <th>
                        签名方式
                      </th>
                      <td>
                        {{firmware.signMethod}}
                      </td>
                      <th>
                        签名
                      </th>
                      <td>
                        {{firmware.sign}}
                      </td>
                    </tr>
                    <tr>
                      <th
                        style="width: 16.5px"
                      >
                        说明
                      </th>
                      <td
                        colspan="5"
                      >
                        {{firmware.description}}
                      </td>
                    </tr>
                  </table>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </section>
      </b-tab>
        <b-tab
          title="升级任务"
        >
          <div>
            <x-table
              title="任务列表"
              ref="taskTable"
              hideSearchBar
              :card="false"
              :options="optionsTask"
              @search="searchHandle"
              @rowBind="rowBind"
              @rowAdd="addHandle"
              @rowDetail="rowView"
              @rowPush="rowPush"
              @rowDelete="rowDelete">
              <template slot="form-createTime" slot-scope="scope">
                <div>{{ new Date(scope.data).cxFormat('yyyy-MM-dd hh:mm')  }} </div>
              </template>
            </x-table>
            <b-sidebar
              title="绑定设备"
              ref="bindSlider"
              idebar-class="sidebar-xl"
              bg-variant="white"
              right
              :visible="visible"
              backdrop
              shadow
              width="80rem"
              @hidden="hide"
            >
              <bind-device
                v-if="visible"
                :firmware="firmware"
                :formData="taskForm"
                @bindSuccess="bindSuccess"
              />
            </b-sidebar>
            <b-sidebar
              title="任务详情"
              ref="bindSlider"
              idebar-class="sidebar-xl"
              bg-variant="white"
              right
              :visible="viewVisible"
              backdrop
              shadow
              width="100rem"
              @hidden="viewHide"
            >
              <task-detail
                v-if="viewVisible"
                :firmware="firmware"
                :formData="taskForm"
              />
            </b-sidebar>
          </div>
        </b-tab>
        <b-tab
          title="升级记录"
        >
          <div>
            <x-table
              title="升级记录"
              ref="historyTable"
              :card="true"
              :options="options"
              @search="searchHandleHistory"
            >
              <template slot="state" slot-scope="scope">
                  <b-badge  variant="light-warning" v-if="scope.data.value === 'waiting'"> {{scope.data.text}} </b-badge>
                  <b-badge  variant="light-primary" v-if="scope.data.value === 'processing'"> {{scope.data.text}} </b-badge>
                  <b-badge  variant="light-success" v-if="scope.data.value === 'success'"> {{scope.data.text}} </b-badge>
                  <b-badge  variant="light-danger" v-if="scope.data.value === 'failed'"> {{scope.data.text}} </b-badge>
              </template>
            </x-table>
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>
<script>
import XTable from '@core/components/cx/table/XTable.vue'
import {
  BTabs, BTab, BCard, BRow, BCol, BButton, VBToggle, VBTooltip, BBadge, BSidebar,
} from 'bootstrap-vue'
import {
  getById, getTaskList, getUpgradeHistoryList, removeTask, patch,
} from '@/api/ops/firmware-upgrade'
import bindDevice from './bindDevice.vue'
import taskDetail from './taskDetail.vue'

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    BRow,
    BCol,
    BButton,
    XTable,
    BBadge,
    BSidebar,
    bindDevice,
    taskDetail,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      viewVisible: false,
      visible: false,
      // 查询状态
      viewState: undefined,
      // 当前tab
      tabIndex: 0,
      firmware: {},
      taskForm: {
        id: undefined,
        name: undefined,
        timeoutSeconds: undefined,
        productId: undefined,
        firmwareId: undefined,
        description: undefined,
        releaseType: 'all',
      },
      taskFormCopy: {
        id: undefined,
        name: undefined,
        timeoutSeconds: undefined,
        productId: undefined,
        firmwareId: undefined,
        description: undefined,
        releaseType: 'all',
      },
      optionsTask: {
        formWidth: '60%',
        // formWidth: 'lg',
        // 导出按钮
        exportBtn: false,
        // 打印按钮
        printBtn: false,
        addBtn: true,
        actions: [
          { action: 'detail', name: '查看', icon: 'EyeIcon', tip: '查看详情' },
          { action: 'bind', name: '绑定设备', icon: 'SlidersIcon', tip: '绑定设备' },
          { action: 'push', name: '推送升级', icon: 'ExternalLinkIcon', tip: '推送升级' },
          // actions: [{ action: 'view', name: '查看', icon: 'EyeIcon', url: '/device-center/device/view/{id}' },
          // { action: 'edit', name: '编辑', icon: 'EditIcon' },
          { action: 'delete', name: '删除', icon: 'Trash2Icon', tip: '删除' },
          // { action: 'disable', name: '禁用', icon: 'SlashIcon' },
        ],
        actionFilter: (action, row) => {
          if (row.mode.value === 'push') {
            return action === 'edit' || action === 'delete' || action === 'bind' || action === 'detail' || action === 'push'
          }
          return action === 'edit' || action === 'delete' || action === 'bind' || action === 'detail'
        },
        columns: [{
          label: '任务名称',
          labelShow: true,
          prop: 'name',
          editDisable: true,
          searchShow: false,
          rules: {
            rule: 'required',
            message: '请输入任务名称',
          },
        }, {
          label: '升级方式',
          labelShow: true,
          prop: 'mode',
          type: 'select',
          rowSelect: true,
          searchShow: false,
          dictData: [{
            text: '平台推送',
            value: 'push',
          }, {
            text: '设备拉取',
            value: 'pull',
          }],
          getDictLabel: option => option.label,
          props: { label: 'text', value: 'value' },
          rules: {
            rule: 'required',
            message: '请选择升级方式',
          },
        }, {
          label: '超时时间',
          labelShow: true,
          prop: 'timeoutSeconds',
          type: 'number',
          rowShow: true,
          searchShow: false,
          rules: {
            rule: 'required',
            message: '请输入超时时间',
          },
        }, {
          label: '创建时间',
          labelShow: true,
          addShow: false,
          editShow: false,
          prop: 'createTime',
          type: 'datetime',
          format: 'yyyy-MM-dd hh:mm',
          tag: true,
          props: { label: 'label2', value: 'value2' },
          searchShow: false,
        },
        {
          label: '描述',
          labelShow: true,
          prop: 'description',
          type: 'textarea',
          rowShow: false,
          searchShow: false,
        },
        ],
      },
      options: {
        formType: 'sidebar',
        formWidth: '60%',
        // formWidth: 'lg',
        cancelOnOutside: true,
        searchFold: true,
        showActions: false,
        lableVertical: true,
        // 导出按钮
        exportBtn: false,
        // 打印按钮
        printBtn: false,
        addBtn: false,
        columns: [{
          label: '设备名称',
          labelShow: true,
          prop: 'deviceName',
          editDisable: false,
          searchShow: true,
        }, {
          label: '任务名称',
          labelShow: true,
          prop: 'taskName',
          searchShow: false,
        }, {
          label: '版本',
          labelShow: true,
          prop: 'version',
          rowShow: true,
          searchShow: false,
        }, {
          label: '状态',
          labelShow: true,
          addShow: false,
          editShow: false,
          prop: 'state',
          rowShow: true,
          searchShow: false,
        }, {
          label: '进度',
          labelShow: true,
          prop: 'progress',
          rowShow: true,
          searchShow: false,
        }, {
          label: '创建时间',
          labelShow: true,
          addShow: false,
          editShow: false,
          prop: 'createTime',
          type: 'datetime',
          format: 'yyyy-MM-dd hh:mm',
          tag: true,
          props: { label: 'label2', value: 'value2' },
          searchShow: false,
        },
        ],
      },
    }
  },
  watch: {
    tabIndex(val) {
      if (val === 1) {
        this.$refs.taskTable.resetHandle()
      } else if (val === 2) {
        this.$refs.historyTable.searchHandle()
      }
    },
  },
  created() {
    // 获取固件信息
    getById(this.$route.params.id).then(resp => {
      this.firmware = resp.data.result
    })
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    searchHandle(page, params, done) {
      if (this.tabIndex === 1) {
        const obj = {
          id: this.$route.params.id,
        }
        params = obj
        getTaskList(page.pageIndex - 1, page.pageSize, params).then(resp => {
          done(resp.data.result.data, resp.data.result.total)
        })
      }
    },
    searchHandleHistory(page, params, done) {
      if (this.tabIndex === 2) {
        const obj = {
          id: this.$route.params.id,
        }
        if (params.deviceName !== null && params.deviceName !== '' && params.deviceName !== undefined) {
          obj.deviceName = params.deviceName
        }
        if (this.viewState !== undefined) {
          obj.state = this.viewState
        }
        getUpgradeHistoryList(page.pageIndex - 1, page.pageSize, obj).then(resp => {
          done(resp.data.result.data, resp.data.result.total)
        })
      }
    },
    rowDelete(data, done) {
      removeTask(data.id).then(() => {
        done()
      })
    },
    addHandle(data, done) {
      if (!data.productId || data.productId === undefined) {
        data.productId = this.firmware.productId
      }
      if (!data.firmwareId || data.firmwareId === undefined) {
        data.firmwareId = this.firmware.id
      }
      patch(data).then(() => {
        done()
      })
    },
    // 绑定设备
    rowBind(data) {
      this.taskForm = data
      this.visible = true
    },
    rowView(data) {
      this.taskForm = data
      this.viewVisible = true
    },
    hide() {
      this.visible = false
    },
    viewHide() {
      this.viewVisible = false
    },
    bindSuccess() {
      this.visible = false
    },
    rowPush(data) {
      console.log('datadatadata', data)
    },
  },
}
</script>
